<template>
  <!-- <NuxtLayout>  -->
  <div class="container">
    <div class="menu-principal" v-show="display">
      <div class="submenu__links">
        <nuxt-link :to="localePath('/scheduling')">
          <div class="submenu__link">
            <img
              src="/img/icons/scheduling-home.png"
              :alt="$t('scheduling.title')"
              :title="$t('scheduling.title')"
              class="submenu__icon"
            />
            <span class="submenu__link_text">{{ $t("scheduling.title") }}</span>
          </div>
        </nuxt-link>
        <nuxt-link :to="false ? localePath('/saturation') : ''">
          <div class="submenu__link submenu__link--disabled">
            <img
              src="/img/icons/saturation-home.png"
              :alt="$t('saturation.title')"
              :title="$t('saturation.title')"
              class="submenu__icon"
            />
            <span class="submenu__link_text">{{ $t("saturation.title") }}</span>
          </div>
        </nuxt-link>
        <nuxt-link :to="localePath('/crew')" v-if="!hasRole('technician')">
          <div class="submenu__link">
            <img
              src="/img/icons/crew-home.png"
              :alt="$t('crew.title')"
              :title="$t('crew.title')"
              class="submenu__icon"
            />
            <span class="submenu__link_text">{{ $t("crew.title") }}</span>
          </div>
        </nuxt-link>
        <div class="submenu__link" @click="locationSelector.setVisible(true)">
          <img
            src="/img/icons/selector-home.png"
            :alt="$t('locationSelector.title')"
            :title="$t('locationSelector.title')"
            class="submenu__icon"
          />
          <span class="submenu__link_text">{{
            $t("locationSelector.title")
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- </NuxtLayout>  -->
</template>

<script setup>
import { useMasters } from "~/store/masters";
import { useLocationSelector } from "~/store/locationSelector";

const locationSelector = useLocationSelector();
const { t } = useI18n();
const masters = useMasters();

const display = ref(false);

definePageMeta({
  layout: "principal",
  middleware: ["auth", "check-admin", "check-language"],
});

useSeoMeta({
  title: `Acciona - MSC / ${t("home.title")}`,
  description: t("home.description"),
});

onMounted(() => {
  masters.setGlobalLoading(false);
  display.value = true;
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
a {
  text-decoration: none;
}

.container {
  background-color: $color-light-300;
  height: 100%;
}

.menu-principal {
  margin: $space-l;
  display: Flex;
  align-items: center;
  justify-content: center;
}

.submenu {
  &__links {
    display: flex;
    flex-flow: row wrap;
    gap: $space-l;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: $space-l;
  }

  &__link {
    border-radius: $border-radius-sm;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    color: $color-dark-900;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: $font-size-md;
    font-weight: bold;
    height: 13em;
    padding: 0.5em;
    text-align: center;
    text-transform: uppercase;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: ease-in-out;
    background-color: white;
    width: 13em;

    &:hover {
      // box-shadow: 0px 0px 5px 3px rgb(199 14 14 / 45%);
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    & .submenu__icon {
      align-self: flex-start;
      display: block;
      height: 7em;
      margin: 0 auto;
      padding: $space-md 0 $space-sm 0;
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.4;
      &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      }
    }
  }
}

.submenu__link_text.submenu__link_text {
  margin: auto;
  text-decoration: none;
}
</style>

